import { getWhatsappLink } from '../whatsappUtil'

export const links = [
  {
    name: 'Home',
    value: '/',
    isExternal: false
  },
  // {
  //   name: 'Cardápio',
  //   value: 'https://deliveryapp.neemo.com.br/delivery/16053/menu',
  //   isExternal: true
  // },
  // {
  //   name: 'Sobre',
  //   value: '/sobre',
  //   isExternal: false
  // },
  // {
  //   name: 'Agenda',
  //   value: '/agenda',
  //   isExternal: false
  // },
  {
    name: 'Faça parte do nosso time',
    value: 'https://curriculos.nekto.com.br/#/vagas/empresa/8',
    isExternal: true
  },
  {
    name: 'Reservas',
    value: 'https://www.getinapp.com.br/santa-maria/carniceria-del-fuego',
    isExternal: true
  },
  {
    name: 'Contato',
    value: '#contato',
    isExternal: false
  }
]
