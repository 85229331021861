import * as React from 'react'
import { motion } from 'framer-motion'
import { Item } from './Item'
import ExternalButton from '../../Button/ExternalButton'
import { GiHamburger } from 'react-icons/gi'
import { getWhatsappLink } from '../../whatsappUtil'

const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 }
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 }
  }
}

export const Navigation = ({ links }) => (
  <motion.ul
    className="bg-dark bg-opacity-75 rounded-lg p-8"
    variants={variants}
  >
    {links.map((link, index) => (
      <Item link={link} key={index} />
    ))}
    {/* <ExternalButton
      className="mt-4"
      title="Delivery"
      link={getWhatsappLink('55999703260', 'Olá, gostaria de fazer um pedido:')}
      icon={<GiHamburger className="h-8 w-8 ml-2" />}
    /> */}
    <ExternalButton
      className="mt-4 py-4"
      title="Seja um franqueado"
      link="https://carniceriadelfuegofranquia.com.br/"
    />
  </motion.ul>
)
